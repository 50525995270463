import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Product = () => (
  <Section id="product">
    <StyledContainer>
      <Subtitle>Produs</Subtitle>
      <SectionTitle>eConta</SectionTitle>
      <ProductText>
        Aplicatia eConta se gaseste in App Store si in Google Play. Cand esti la
        birou, poti sa iti accesezi rapoartele din orice browser.
      </ProductText>
    </StyledContainer>
  </Section>
)

export default Product

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`
const ProductText = styled.p`
  text-align: center;
`
